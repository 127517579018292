import React from "react";
import { Props } from "./types";
import { TableCell, TableRow } from "@/components/ui/table";

export const ProxyRow: React.FC<Props> = ({ index }) => (
  <TableRow>
    <TableCell>{index}</TableCell>
    <TableCell>Proxy name</TableCell>
    <TableCell className="text-center">Proxy name</TableCell>
    <TableCell className="text-center">Proxy name</TableCell>
  </TableRow>
)
