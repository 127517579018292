import React, { useCallback } from 'react'
import { Props } from './types'
import { useGetProfileChildAccountsQuery } from '@/features/profile'
import { Modal } from '@/components/common'
import { cn } from '@/lib/utils'

export const AccountsModal: React.FC<Props> = ({ open, close, phoneID }) => {
  const { data: { data: childAccounts = '' } = {}, isLoading } = useGetProfileChildAccountsQuery({ id: phoneID })
  const getLessOrMoreResults = useCallback((key: string) => (childAccounts as any)?.[key], [childAccounts])

  return (
    <Modal
      isLoading={isLoading}
      open={open}
      close={close}
      title={`Child accounts (${phoneID})`}
      contentClassName="min-w-[600px]"
    >
      <div
        className={cn(
          'flex gap-2',
          !((getLessOrMoreResults('less')?.length || 0) + (getLessOrMoreResults('more')?.length || 0) || 0)
            ? 'pb-10'
            : '',
        )}
      >
        <div className="w-1/2">
          <h4 className="text-lg font-semibold mb-1">
            Less than 100 followers ({getLessOrMoreResults('less')?.length || 0})
          </h4>
          {getLessOrMoreResults('less')?.length ? (
            <div className="flex flex-col max-h-[120px] overflow-auto border border-blue-200 p-1 rounded-md">
              {getLessOrMoreResults('less')?.map((childAccount: string) => (
                <span key={childAccount}>{childAccount}</span>
              ))}
            </div>
          ) : (
            <span className="text-muted-foreground block">Less results not existed</span>
          )}
        </div>
        <div className="w-1/2">
          <h4 className="text-lg font-semibold mb-1">
            More than 100 followers ({getLessOrMoreResults('more')?.length || 0})
          </h4>
          {getLessOrMoreResults('more')?.length ? (
            <div className="flex flex-col max-h-[120px] overflow-auto border border-blue-200 p-1 rounded-md">
              {getLessOrMoreResults('more')?.map((childAccount: string) => (
                <span key={childAccount}>{childAccount}</span>
              ))}
            </div>
          ) : (
            <span className="text-muted-foreground block">More results not existed</span>
          )}
        </div>
      </div>
    </Modal>
  )
}
