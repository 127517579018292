import { Tabs } from '@/components/common'
import { ComparingTool, GrowthTracker, Proxies, Roadmap, VPSTool } from './components'

export const ToolsPage = () => (
  <div className="container max-w-[900px] mx-auto mt-16 mb-4 py-6 bg-white dark:bg-background dark:border dark:border-secondary rounded-xl">
    <Tabs
      tabs={[
        { label: 'VPS Tool', value: 'vps-tool', children: <VPSTool /> },
        { label: 'Roadmap', value: 'roadmap', children: <Roadmap /> },
        { label: 'Growth Tracker', value: 'growth-tracker', children: <GrowthTracker /> },
        { label: 'Comparing Tool', value: 'comparing-tool', children: <ComparingTool /> },
        { label: 'Proxies', value: 'proxies', children: <Proxies /> },
      ]}
      contentClassName="mt-7"
    />
  </div>
)
