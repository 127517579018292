import { Table, TableBody, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { cn } from "@/lib/utils"
import { ProxyRow } from "./components"

export const Proxies = () => <div>
  <Table>
    <TableHeader>
      <TableRow>
        <TableHead className={thClassName}>#</TableHead>
        <TableHead className={thClassName}>Proxy</TableHead>
        <TableHead className={cn(thClassName, "text-center")}>Speed</TableHead>
        <TableHead className={cn(thClassName, "text-center")}>Last updated at</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      {[...new Array(100)].map((_, index) => <ProxyRow key={index} index={index} />)}
    </TableBody>
  </Table>
</div>

const thClassName = 'sticky top-0 z-[5] bg-slate-100 first:rounded-tl-xl first:rounded-bl-xl last:rounded-tr-xl last:rounded-br-xl dark:bg-secondary text-xs'
